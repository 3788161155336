import _ from 'lodash';
import _fp from 'lodash/fp';
export function getValue(s, fieldName, language) {
    return (_.get(s, ['properties', 'languages', language, fieldName]) ||
        _.get(s, ['properties', 'languages', 'en', fieldName]) ||
        _.get(s, ['properties', fieldName]));
}
export const processPhoneNumbers = _fp.flow([
    _fp.get('properties.phoneNumber'),
    _fp.split(';'),
    _fp.map(_fp.trim),
]);
export function translateProps(rawData, currLanguage, languageKeys) {
    const props = _fp.flow([
        _fp.map((k) => ({
            [k]: getValue(rawData, k, currLanguage),
        })),
        _fp.assignInAll,
    ])(languageKeys);
    return props;
}
