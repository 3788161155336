import { PrivacyPolicy_en as privacyPolicyText } from './PrivacyPolicy';
import { TermsOfService_en as termsOfServiceText } from './TermsOfService';

const APP_TITLE = 'Care Finder';

export const en = {
  app: {
    title: APP_TITLE,
  },
  header: {
    title: APP_TITLE,
    login: 'Sign In',
    account: 'Account',
    logout: 'Sign Out',
  },
  footer: {
    contactUs: 'Need help? Contact us.',
    contactEmail: 'mailto:support@template.com',
    privacyPolicyTitle: 'Privacy Policy',
    privacyPolicy: 'privacy policy',
    privacyPolicyText,
    termsOfServiceTitle: 'Terms of Service',
    termsOfService: 'terms of service',
    termsOfServiceText,
  },
  page_titles: {
    sign_in: 'Sign In Page',
    sign_up: 'Sign Up Page',
    home: 'Search for Resources',
  },
  common: {
    next: 'Next',
    cancel: 'Cancel',
    back: 'Back',
    save: 'Save',
    success_submit: 'Successfully Submitted',
    failed_submit: 'Submission Failed',
  },
  signin: {
    sign_up_title: "Are you a Parkinson's Care Provider?",
    sign_up_description: 'Sign up here to get listed',
    sign_in_title: 'Need to update your listing?',
    sign_in_description: 'Sign in here',
  },
  content: {
    care_finder: {
      where: 'Where are you looking for services?',
      where_placeholder: 'Enter Postal Code',
      distance: 'Within what distance?',
      services: 'What service(s) are you looking for?',
      select_all: 'Select All',
      search: 'Find your Care Specialist',
      search_results_title: 'Current Search Criteria',
      modify: 'Modify Search Criteria',
      result_count: '{{resultCount}} matches to criteria',
      services_offered: 'Services Offered:',
      km_display: '{{value}} km',
      all_selected: 'All services selected',
      no_results_header: 'No Services were found with current criteria.',
      no_results_subheader:
        'Please increase search distance, or expand services selection.',
      postal_code_error: 'Postal code not found',
      learn_more: 'Learn More',
      services_selected: 'Selected service(s)',
      virtual: 'Show only locations offering virtual services',
      in_home: 'Show only locations offering in home services',
      distance_selected: 'Distance',
      location_selected: 'Location',
      not_applicable: 'Not applicable',
      provider: 'Provider:',
      years_experience: 'Years of Experience:',
      description: 'Description:',
      offers_virtual: 'Offers Virtual Services',
      offers_in_home: 'Offers In Home Services',
    },
  },
  signUp: {
    header: 'Join the Parkinson Canada Care Network',
    subheader:
      'Join the Parkinson Canada Care Network by completing the following.',
    subheader2:
      'Once your details have been confirmed, and any outstanding information is collected, you will be notified if / when you are added to the Parkinson Canada Care Network. If you offer services in english and french, use the  EN  FR  control to toggle between each and supply the appropriate info for each language.  Any fields not translated will be shown in the default language set.',
    about: 'About You',
    name: 'Your Name *',
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email Address',
    services: 'What service(s) do you offer? *',
    experience: "Parkinson's Patient Experience *",
    experience_description: 'What year did you start working in this field?',
    disclaimer:
      'Please be sure to save all locations prior to submitting the form.',
    translatable: 'The following fields are available for translation',
    translation_disclaimer:
      'Any fields not translated will be shown in the default language set.',
    // named to match the api so we can dynamically generate the fields Do not rename
    translatable_fields: {
      name: 'Service Location Name',
      businessHours: 'Business Hours',
      business_hours_description:
        'Monday - Wednesday: 8am - 5 pm, Thursday - Friday: 10am - 6pm',
      fees: 'Fees',
      referralRequired: 'Is a referral required?',
      title: 'Title',
      quote: 'Provider Description',
    },
    locations: {
      locations: 'Locations',
      header: 'Location Details',
      name_placeholder: 'location name',
      services: 'Service(s) offered at this location?',
      phone_number: 'Phone Number',
      address: 'Address',
      map_link: 'Service Google Map Link',
      website: 'Website URL',
      virtual: 'Does this location offer virtual services?',
      in_home: 'Does this location offer in home services?',
      add: 'Add Another Location',
      no_locations: 'No locations set, please add a location below.',
      add_location: 'Add a Location',
    },
    minimum_services: 'Minimum of 1 service required',
    minimum_locations: 'Minimum of 1 location required',
    submit: 'Submit to Care Network',
    success_header: 'Thank you for your submission',
    success_message:
      'Your submission has been received. You will be receiving an email with instructions on how to complete your account. You will be notified when your submission has been reviewed.',
    validation: {
      matches: {
        phone_number: 'Please enter a valid phone number! ex:',
      },
      required: {
        general: 'This is a required field!',
        phone_number: 'A phone number is required!',
      },
    },
  },
  not_implemented: 'State "{{connectionState}}" not implemented"',
  survey: {
    label: 'Tell us how we did!',
  },
};
