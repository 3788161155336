export const COLOURS = {
    Primary: '#D50132',
    Secondary: '#4F232B',
    LighterGrey: '#C6C6C6',
    LightGrey: '#B3B4B4',
    MediumGrey: '#ACA8A8',
    DarkGrey: '#7B7B7C',
    DarkerGrey: '#686163',
    PinkGrey: '#D0BEC3',
    DarkPinkGrey: '#A98B92',
    Disabled: '#979797',
    Black: '#000000',
    White: '#ffffff',
    Red: '#d32f2f',
    Green: '#017D02',
    Orange: '#FFA500',
    LightYellow: '#FFFA6D',
    LightRed: '#FA4040',
    LightBlue: '#64b1ff',
    LightGreen: '#66BB6A',
};
