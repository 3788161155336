import { ConnectionService, ConfigService } from 'hive-client-utils';
import _ from 'lodash';

const KEY_TOKEN = 'hive-token';
const KEY_TOKEN_EXPIRATION = 'hive-token-expiration';
const KEY_USERNAME = 'hive-username';
const PUBLIC_BEE_NAME = (window as any)._env_.REACT_APP_PUBLIC_BEE_NAME;
const PUBLIC_BEE_PASS = (window as any)._env_.REACT_APP_PUBLIC_BEE_PASS;

const setBeeFn = (token: ConnectionService.Token, username: string) => {
  localStorage.setItem(KEY_TOKEN, token.access_token);
  localStorage.setItem(KEY_TOKEN_EXPIRATION, token.access_token_expiry);
  localStorage.setItem(KEY_USERNAME, username);
};

const clearBeeFn = () => {
  localStorage.removeItem(KEY_TOKEN);
  localStorage.removeItem(KEY_TOKEN_EXPIRATION);
  localStorage.removeItem(KEY_USERNAME);
};

const loadBeeFn = () => {
  const tokenExpiration = localStorage.getItem(KEY_TOKEN_EXPIRATION);

  // Check if we have a stored token, if so, we don't need the login screen
  const info: ConnectionService.StorageToken = {
    token: localStorage.getItem(KEY_TOKEN) || undefined,
    tokenExpiration: tokenExpiration ? parseInt(tokenExpiration) : undefined,
    username: localStorage.getItem(KEY_USERNAME) || undefined,
  };

  return info;
};

ConnectionService.setupCallbacks({
  setBeeFn,
  clearBeeFn,
  loadBeeFn,
});

export const createPublicBee = () => {
  ConfigService.config$.subscribe({
    next: (config: any) => {
      return ConnectionService.signInAsBee(
        config,
        PUBLIC_BEE_NAME,
        PUBLIC_BEE_PASS
      );
    },
  });
};

// Init bee from local storage if possible (when config is available)
ConfigService.config$.subscribe({
  next: (config: any) => {
    ConnectionService.signInFromStorage(config).catch(() => {
      const { pathname } = window.location;
      if (_.startsWith(pathname, '/oidc')) {
        return;
      }
      return ConnectionService.signInAsBee(
        config,
        PUBLIC_BEE_NAME,
        PUBLIC_BEE_PASS
      );
    });
  },
  error: (error: any) => console.error('Could not get configuration', error),
});
