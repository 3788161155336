const REGEX_EMAIL = /^\S+@\S+$/;
function validateEmail(email, options) {
    if (!email) {
        if (!!(options === null || options === void 0 ? void 0 : options.ignoreEmpty)) {
            return true;
        }
    }
    const match = REGEX_EMAIL.exec(email || '');
    return !!match;
}
export const ValidationService = {
    validateEmail,
};
