import { COLOURS } from './colours';
const styleOverrides = {
    standard: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '16px',
        color: COLOURS.DarkGrey,
    },
};
export const MuiSelect = {
    styleOverrides,
};
