import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
const YEARS = [];
for (let m = moment().year(); m >= 1900; m--) {
    YEARS.push(m);
}
export function YearSelector({ fullWidth, label, placeholder, value, setValue, helperText, }) {
    const onChange = useCallback((event) => {
        // convert number to moment since dropdown list _.map(YEARS, ..) is a list of numbers
        const selectedYear = typeof event.target.value === 'number'
            ? moment([event.target.value])
            : event.target.value;
        setValue(selectedYear);
    }, [setValue]);
    return (_jsxs(FormControl, Object.assign({ fullWidth: fullWidth, error: !!helperText }, { children: [_jsx(InputLabel, { children: label }), _jsx(Select, Object.assign({ label: label, placeholder: placeholder, value: moment(value).year(), onChange: onChange, MenuProps: {
                    className: 'year-selector',
                } }, { children: _.map(YEARS, (v) => (_jsx(MenuItem, Object.assign({ value: v }, { children: v }), v))) })), helperText && _jsx(FormHelperText, { children: helperText })] })));
}
