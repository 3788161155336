import { ThemeProvider } from '@mui/material';
import { useAppVersion, useTitleEffect } from 'hive-react-utils';
import { Stack } from '@mui/material';
import { useEffect, lazy, Suspense } from 'react';
import {
  CommonDataContextProvider,
  MessageBarProvider,
} from 'carefinder-shared-utilities';
import Geocode from 'react-geocode';

import { userTheme } from './theme';
import { DataContextProvider } from './contexts/DataContext';
import FallbackSpinner from './components/FallbackSpinner';

const SurveyApp = lazy(() => import('./components/Survey'));
const Main = lazy(() => import('./content/Main'));

const GOOGLE_API_KEY =
  process.env.REACT_APP_GOOGLE_API_KEY ||
  (window as any)._env_.REACT_APP_GOOGLE_API_KEY;

export default function App() {
  useTitleEffect('app.title');

  const { version } = useAppVersion();

  useEffect(() => {
    Geocode.setApiKey(GOOGLE_API_KEY);
    console.debug(`App Version: ${version}`);
  }, [version]);

  useEffect(() => console.debug(`App Version: ${version}`), [version]);

  return (
    <ThemeProvider theme={userTheme}>
      <MessageBarProvider>
        <CommonDataContextProvider>
          <DataContextProvider>
            <Suspense fallback={<FallbackSpinner fullScreen />}>
              <Stack className="survey-wrapper">
                <SurveyApp />
              </Stack>
              <Main />
            </Suspense>
          </DataContextProvider>
        </CommonDataContextProvider>
      </MessageBarProvider>
    </ThemeProvider>
  );
}
