export const servicesOffered = {
    en: {
        physio: 'Physiotherapist',
        community: 'Community Groups',
        psychiatrist: 'Psychiatrist',
        movement: 'Movement Specialist',
        government: 'Government Services',
        rehab: 'Rehabilitation Specialist',
        speech: 'Speech Therapist',
        neurologist: 'Neurologist',
        dietitian: 'Dietitian',
        occupation: 'Occupational Therapist',
        assistant: 'Physician Assistant',
        specialist: 'Specialist in Geriatric Medicine',
        nurse: 'Parkinson Nurse',
        psychologist: 'Psychologist',
        remedial: 'Remedial Therapist',
        home: 'Home Care',
        hospice: 'Hospice',
        devices: 'Medical Devices',
        assessment: 'Assessment and Evaluation',
        dental: 'At Home Dental Service',
        coordination: 'Care Coordination',
        modifications: 'Home Modifications',
        seniors: 'Seniors Services',
        personal: 'Personal Care',
        long_term: 'Long Term Care Facility',
        massage: 'Massage Therapy',
        mental_health: 'Mental Health',
        occupational: 'Occupational Therapy',
    },
    fr: {
        physio: 'Physiothérapeute',
        community: 'Groupes communautaires',
        psychiatrist: 'Psychiatre',
        movement: 'Spécialiste du mouvement',
        government: 'Services publics',
        rehab: 'Spécialiste de la réadaptation',
        speech: 'Orthophoniste',
        neurologist: 'Neurologue',
        dietitian: 'Diététicien',
        occupation: 'Ergothérapeute',
        assistant: 'Assistant médical',
        specialist: 'Spécialiste en médecine gériatrique',
        nurse: 'Infirmière spécialiste de la maladie de Parkinson',
        psychologist: 'Psychologue',
        remedial: 'Thérapeute correctif',
        home: 'Soins à domicile',
        hospice: 'Centre de soins palliatifs',
        devices: 'Dispositifs médicaux',
        assessment: 'Bilan et évaluation',
        dental: 'Service dentaire à domicile',
        coordination: 'Coordination des soins',
        modifications: 'Modifications du domicile',
        seniors: 'Services aux personnes âgées',
        personal: 'Soins personnels',
        long_term: 'Établissement de soins de longue durée',
        massage: 'Massothérapie',
        mental_health: 'Santé mentale',
        occupational: 'Ergothérapie',
    },
};
