const styleOverrides = {
    root: {
        '&.search-results-anchor': {
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            wordBreak: 'break-all',
            backgroundColor: 'transparent',
        },
    },
};
export const MuiLink = {
    styleOverrides,
};
