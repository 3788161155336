import { useCurrentLanguage, useInstancesByQuery } from 'hive-react-utils';
import _ from 'lodash';
import { useMemo } from 'react';
import { KeysOfResourceLocationForLanguage, } from '../types/resourceLocation';
import { translateProps, processPhoneNumbers } from '../utils/utils';
export function useApprovedResourceLocations(providerID) {
    const rawLocations = useInstancesByQuery('carefinder', 'approvedResourceLocations', 'resourceLocation', {
        debounce: true,
        debounceWait: 1000,
    });
    const language = useCurrentLanguage();
    // TODO: make this into something we can reuse instead of copying it everywhere
    return useMemo(() => {
        return _.map(rawLocations, (r) => {
            const translatedProps = translateProps(r, language, KeysOfResourceLocationForLanguage);
            const phoneNumbers = processPhoneNumbers(r);
            return Object.assign(Object.assign({}, r), { properties: Object.assign(Object.assign(Object.assign({}, r.properties), translatedProps), { phoneNumbers }) });
        });
    }, [rawLocations, language]);
}
