import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// The following just need to be imported to start things
import './services/LocalizationService';
import './services/LoginService';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
