import { assertLoggableConnection, LoggedConnectionService, } from 'hive-analytics-react';
import _ from 'lodash';
import moment from 'moment';
function createProvider(provider, resourceLocations, redirectUri) {
    const connection = assertLoggableConnection();
    return connection.bee.api.invoke('createProvider', provider, resourceLocations, redirectUri);
}
function deleteMyAccount() {
    const connection = assertLoggableConnection();
    return connection.bee.api
        .invoke('deleteMyAccount')
        .then(() => LoggedConnectionService.releaseBee('DELETE_MY_ACCOUNT'));
}
function updateProvider(id, props) {
    if (_.isEmpty(props) || !id) {
        return Promise.resolve();
    }
    const connection = assertLoggableConnection();
    return connection.bee.api.invoke('updateProvider', id, props);
}
function createResourceLocation(resourceLocation) {
    const connection = assertLoggableConnection();
    resourceLocation = _.omit(resourceLocation, 'phoneNumbers');
    return connection.bee.api.invoke('createResourceLocation', resourceLocation);
}
function updateResourceLocation(id, props) {
    if (_.isEmpty(props) || !id) {
        return Promise.resolve();
    }
    const connection = assertLoggableConnection();
    props = _.omit(props, 'phoneNumbers');
    return connection.bee.api.invoke('updateResourceLocation', id, props);
}
function deleteResourceLocation(id) {
    if (!id) {
        return Promise.resolve();
    }
    const connection = assertLoggableConnection();
    return connection.bee.api.invoke('deleteResourceLocation', id);
}
function formatDate(date) {
    if (!date) {
        return '';
    }
    return moment(date).format('YYYY');
}
export const ProviderService = {
    createProvider,
    deleteMyAccount,
    updateProvider,
    createResourceLocation,
    updateResourceLocation,
    deleteResourceLocation,
    formatDate,
};
