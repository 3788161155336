import { useInstancesByQuery, useCurrentLanguage } from 'hive-react-utils';
import _ from 'lodash';
import { useMemo } from 'react';
import { KeysOfProviderForLanguage } from '../types';
import { translateProps } from '../utils/utils';
export function useProviders() {
    const rawProviders = useInstancesByQuery('carefinder', 'activeProviders', 'provider', {});
    const language = useCurrentLanguage();
    return useMemo(() => {
        return _.map(rawProviders, (p) => {
            const translatedProps = translateProps(p, language, KeysOfProviderForLanguage);
            return Object.assign(Object.assign({}, p), { properties: Object.assign(Object.assign(Object.assign({}, p.properties), translatedProps), { name: _.join(_.compact([p.properties.lastName, p.properties.firstName]), ', ') }) });
        });
    }, [rawProviders, language]);
}
