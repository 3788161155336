import { COLOURS } from './colours';
import { spacing } from './constants';
const styleOverrides = {
    root: {
        boxShadow: 'inset 0px 1px 3px 0px rgba(0,0,0,0.5)',
        padding: `${spacing * 2.5}px`,
        backgroundColor: COLOURS.LightGrey + '40', // adding 25% opacity to color
    },
};
export const MuiCard = {
    styleOverrides,
};
