const styleOverrides = {
    paper: {
        borderRadius: 8,
        '.year-selector .MuiMenu-paper&': {
            maxHeight: '200px',
        },
    },
};
export const MuiPopover = {
    styleOverrides,
};
