import { createTheme, ThemeOptions } from '@mui/material';
import { theme as baseTheme } from 'carefinder-shared-utilities';
import { MuiFormControl } from './formControl';
import { MuiAccordion } from './accordion';
import { MuiButton } from './button';

const updates: ThemeOptions = {
  components: {
    MuiAccordion,
    MuiButton,
    MuiFormControl,
  },
};

export const userTheme = createTheme(baseTheme, updates);
