import { ComponentsOverrides } from '@mui/material';
import { COLOURS, spacing } from 'carefinder-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiFormControl'] = {
  root: {
    width: '100%',
    '.MuiFilledInput-root': {
      marginRight: spacing * 3,
      fontSize: '14px',
      lineHeight: '16px',
      backgroundColor: 'unset',
    },
    '.MuiFormLabel-root': {
      color: COLOURS.DarkGrey,
    },
    '&.MuiToolbar-root': {
      width: 'unset',
    },
    '.hidden&': {
      display: 'none',
    },
  },
};

export const MuiFormControl = {
  styleOverrides,
};

const labelStyleOverrides: ComponentsOverrides['MuiFormControlLabel'] = {
  root: {},
  label: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    color: COLOURS.Black,
  },
};

export const MuiFormControlLabel = {
  styleOverrides: labelStyleOverrides,
};
