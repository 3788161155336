import _fp from 'lodash/fp';
import { useMemo } from 'react';
function getFeatureFlags() {
    return _fp.flow([
        _fp.get('_env_.REACT_APP_FEATURE_FLAGS'),
        _fp.split(','),
        _fp.map(_fp.flow([
            _fp.trim,
            _fp.toUpper,
            (featureName) => ({ [featureName]: true }),
        ])),
        _fp.assignInAll,
    ])(window);
}
export function useFeatureFlags() {
    return useMemo(getFeatureFlags, []);
}
