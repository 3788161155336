import { fontFamily } from './constants';
const variants = [
    {
        props: { variant: 'selectable' },
        style: {
            minWidth: 'unset',
            padding: 0,
            fontWeight: 'lighter',
        },
    },
    {
        props: { variant: 'selectableSelected' },
        style: {
            minWidth: 'unset',
            padding: 0,
            fontWeight: 'bold',
        },
    },
];
const styleOverrides = {
    root: Object.assign(Object.assign({}, fontFamily), { '.translable-language-toggle&': {
            minWidth: 'unset',
        } }),
};
export const MuiButton = {
    variants,
    styleOverrides,
};
