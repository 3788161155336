import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useProviders, useResourceLocations, useFeatureFlags } from '../hooks';
import { CommonDataContext } from './CommonDataContext';
// Note: the provider can NOT be in the same file as the context. That causes a circular refence
export function CommonDataContextProvider({ children, }) {
    const providers = useProviders();
    const resourceLocations = useResourceLocations();
    const featureFlags = useFeatureFlags();
    const value = useMemo(() => ({
        providers: providers || [],
        resourceLocations: resourceLocations || [],
        featureFlags,
    }), [providers, resourceLocations, featureFlags]);
    return (_jsx(CommonDataContext.Provider, Object.assign({ value: value }, { children: children })));
}
function useLogValue(providers) {
    throw new Error('Function not implemented.');
}
