import { Box, CircularProgress } from '@mui/material';

interface FallbackSpinnerProps {
  fullScreen?: boolean;
}
export default function FallbackSpinner({
  fullScreen,
}: FallbackSpinnerProps): JSX.Element {
  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      height={fullScreen ? '100vh' : '100%'}
      aria-live="assertive"
    >
      <CircularProgress />
    </Box>
  );
}
