import { COLOURS } from './colours';
import { primary, fontFamily, spacing } from './constants';
export const typography = {
    h6: {
        fontWeight: 'unset',
    },
    body1: {
        color: primary.main,
    },
    body2: {
        color: primary.main,
    },
};
const styleOverrides = {
    root: Object.assign(Object.assign({}, fontFamily), { color: 'unset', '&.error-message': {
            scrollMarginTop: spacing * 8,
        } }),
    body1: {
        'header .MuiIconButton-root &': {
            // Undo the color in the theme for typography.body1 when in the header menu button
            color: 'unset',
            whiteSpace: 'nowrap',
        },
        '&.map-item': {
            position: 'absolute',
            color: COLOURS.White,
            fontWeight: 'bold',
            left: '50%',
            top: '50%',
            zIndex: '1000',
            transform: 'translate(-45%, -75%)',
        },
    },
};
export const MuiTypography = {
    styleOverrides,
};
