import { elevation, spacing } from './constants';
const styleOverrides = {
    root: {
        'footer & a > svg': {
            width: 'auto',
            height: '22px',
        },
        svg: {
            pointerEvents: 'none',
        },
        header: {
            '> .MuiToolbar-root': {
                display: 'flex',
                justifyContent: 'space-between',
            },
            '& a': {
                display: 'flex',
                pointerEvents: 'all',
                '> img': {
                    marginTop: `${spacing}px`,
                    marginBottom: `${spacing}px`,
                    padding: '0',
                    height: '10vh',
                    alignSelf: 'center',
                    pointerEvents: 'none',
                },
            },
            'header & div.header-tabs': {
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translate(-50%, 0%)',
            },
        },
    },
};
const defaultProps = {
    elevation,
};
export const MuiPaper = {
    styleOverrides,
    defaultProps,
};
