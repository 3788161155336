import { ComponentsOverrides } from '@mui/material';
import { spacing } from 'carefinder-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiButton'] = {
  root: {
    '.survey-popover&': {
      // Eyeballed...
      padding: `${spacing * 0.5}px ${spacing * 3}px`,
      borderRadius: `${spacing * 2.5}px ${spacing * 2.5}px 0 0`,
    },
  },
};

export const MuiButton = {
  styleOverrides,
};
