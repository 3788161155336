import { PrivacyPolicy_fr as privacyPolicyText } from './PrivacyPolicy';
import { TermsOfService_fr as termsOfServiceText } from './TermsOfService';

const APP_TITLE = 'Nouvelle application';

export const fr = {
  app: {
    title: APP_TITLE,
  },
  header: {
    title: APP_TITLE,
    login: 'Connexion',
    account: 'Compte',
    logout: 'Déconnexion',
  },
  footer: {
    contactUs: "Besoin d'aide? Contactez-nous.",
    contactEmail: 'mailto:support@template.com',
    privacyPolicyTitle: 'Politique de confidentialité',
    privacyPolicy: 'politique de confidentialité',
    privacyPolicyText,
    termsOfServiceTitle: 'Modalités de service',
    termsOfService: 'modalités de service',
    termsOfServiceText,
  },
  page_titles: {
    sign_in: "Page d'inscription",
    sign_up: 'Page de création de compte',
    home: 'Recherche de ressources',
  },
  common: {
    next: 'Suivant',
    cancel: 'Annuler',
    back: 'Retour',
    save: 'Savegardez',
    success_submit: 'Soumis avec succès',
    failed_submit: 'Echec de la soumission',
  },
  signin: {
    sign_in_title:
      "Êtes-vous un fournisseur de soins pour la maladie de Parkinson's?",
    sign_in_description: 'Inscrivez-vous ici pour être répertorié',
    sign_up_title: 'Besoin de mettre à jour votre liste?',
    sign_up_description: 'Inscrivez-vous ici',
  },
  content: {
    care_finder: {
      where: 'Où recherchez-vous des services?',
      where_placeholder: 'Entrez le code postal',
      distance: 'A quelle distance?',
      services: 'Quel(s) service(s) recherchez-vous?',
      select_all: 'Tout sélectionner',
      search: 'Trouvez votre spécialiste des soins',
      search_results_title: 'Critères de recherche actuels',
      modify: 'Modifier les critères de recherche',
      result_count: '{{resultCount}} correspondances aux critères',
      services_offered: 'Services offerts:',
      virtual: 'Afficher uniquement les lieux offrant des services virtuels',
      in_home: 'Afficher uniquement les lieux offrant des services à domicile',
      km_display: '{{value}} km',
      all_selected: 'Tous les services sont sélectionnés',
      no_results_header:
        "Aucun service n'a été trouvé avec les critères actuels",
      no_results_subheader:
        'Veuillez augmenter la distance de recherche ou élargir la sélection des services.',
      postal_code_error: 'Code postal non trouvé',
      learn_more: 'En savoir plus',
      services_selected: 'Service(s) sélectionné(s)',
      distance_selected: 'Distance',
      location_selected: 'Localisation',
      not_applicable: 'Non applicable',
      provider: 'Prestataire:',
      years_experience: "Années d'expérience:",
      description: 'Description:',
      offers_virtual: 'Offres de services virtuels',
      offers_in_home: 'Offres de services à domicile',
    },
  },
  signUp: {
    header: 'Rejoignez le réseau de soins de Parkinson Canada',
    subheader:
      'Rejoignez le réseau de soins de Parkinson Canada en remplissant les formulaires suivants.',
    subheader2:
      "Une fois que vos coordonnées auront été confirmées et que les renseignements manquants auront été recueillis, vous serez avisé si/quand vous serez ajouté au réseau de soins de Parkinson Canada. Si vous offrez des services en anglais et en français, utilisez la commande EN FR pour passer de l'une à l'autre et fournissez les informations appropriées pour chaque langue.  Tous les champs non traduits seront affichés dans la langue par défaut.",
    about: 'À propos de vous',
    name: 'Votre nom *',
    first_name: 'prénom',
    last_name: 'nom de famille',
    email: 'adresse courriel',
    services: 'Quel(s) service(s) offrez-vous ? *',
    experience: 'Expérience des patients atteints de la maladie de Parkinson *',
    experience_description:
      'En quelle année avez-vous commencé à travailler dans ce domaine?',
    disclaimer:
      'Veillez à sauvegarder tous les emplacements avant de soumettre le formulaire.',
    translatable: 'Les champs suivants sont disponibles pour la traduction',
    translation_disclaimer:
      'Tous les champs non traduits seront affichés dans la langue par défaut.',
    // named to match the api so we can dynamically generate the fields Do not rename
    translatable_fields: {
      name: "Nom de l'emplacement du service",
      businessHours: "Heures d'ouverture",
      business_hours_description:
        'Lundi - mercredi : 8h - 17h, jeudi - vendredi : 10h - 18h',
      fees: 'Frais',
      referralRequired: 'Une recommandation est-elle nécessaire?',
      title: 'titre',
      quote: 'Description du fournisseur',
    },
    locations: {
      locations: 'Emplacements',
      header: "Détails de l'emplacement",
      name_placeholder: 'Nom du lieu',
      services: 'Service(s) offert(s) à cet endroit?',
      phone_number: 'Numéro de téléphone',
      address: 'Adresse',
      map_link: 'Lien vers la carte Google du service',
      virtual: 'Ce lieu offre-t-il des services virtuels?',
      in_home: 'Ce lieu offre-t-il des services à domicile?',
      website: 'URL du site Web',
      add: 'Ajouter un autre site',
      no_locations:
        "Aucun lieu n'a été défini, veuillez ajouter un lieu ci-dessous.",
      add_location: 'Ajouter un Lieu',
    },
    minimum_services: "Minimum d'un service requis",
    minimum_locations: "Minimum d'un emplacement requis",
    submit: 'Soumettre au réseau de soins',
    success_header: 'Merci pour votre soumission',
    success_message:
      'Votre soumission a été reçue. Vous recevrez un courriel avec des instructions sur la façon de compléter votre compte. Vous serez informé lorsque votre soumission aura été examinée.',
    validation: {
      matches: {
        phone_number: 'Veuillez saisir un numéro de téléphone valide! ex:',
      },
      required: {
        general: 'Ce champ est obligatoire!',
        phone_number: 'Un numéro de téléphone est requis!',
      },
    },
  },
  not_implemented: 'État "{{connectionState}}" non-implanté',
  survey: {
    label: 'Dites-nous comment nous avons fait!',
  },
};
