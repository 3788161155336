const styleOverrides = {
    root: {
        'header &': {
            justifyContent: 'end',
            padding: '0px',
        },
    },
};
export const MuiIconButton = {
    styleOverrides,
};
