import { assertLoggableConnection } from 'hive-analytics-react';
function addSurvey(body, version) {
    const connection = assertLoggableConnection();
    return connection.bee.api.invoke('survey.addSurvey', body, version);
}
function updateSurvey(surveyID, body, version) {
    const connection = assertLoggableConnection();
    return connection.bee.api
        .invoke('survey.updateSurvey', surveyID, body, version);
}
function addSurveyResults(responses, version) {
    const connection = assertLoggableConnection();
    return connection.bee.api
        .invoke('survey.addSurveyResults', responses, version);
}
export const SurveyService = {
    addSurvey,
    addSurveyResults,
    updateSurvey,
};
