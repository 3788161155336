import { ComponentsOverrides } from '@mui/material';

const styleOverrides: ComponentsOverrides['MuiAccordion'] = {
  root: {
    '.box-shadow-none&': {
      boxShadow: 'none',
    },
  },
};

export const MuiAccordion = {
  styleOverrides,
};
