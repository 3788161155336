import { useCurrentLanguage, useInstancesByClassName } from 'hive-react-utils';
import { KeysOfResourceLocationForLanguage, } from '../types/resourceLocation';
import _ from 'lodash';
import { useMemo } from 'react';
import { translateProps, processPhoneNumbers } from '../utils/utils';
export function useResourceLocations() {
    const rawLocations = useInstancesByClassName('carefinder', 'resourceLocation');
    const language = useCurrentLanguage();
    return useMemo(() => {
        return _.map(rawLocations, (r) => {
            const translatedProps = translateProps(r, language, KeysOfResourceLocationForLanguage);
            const phoneNumbers = processPhoneNumbers(r);
            return Object.assign(Object.assign({}, r), { properties: Object.assign(Object.assign(Object.assign({}, r.properties), translatedProps), { phoneNumbers }) });
        });
    }, [rawLocations, language]);
}
