import { createContext, useContext, useMemo } from 'react';
import {
  Provider,
  ResourceLocation,
  useMyProvider,
  useResourceLocationsByProviderId,
} from 'carefinder-shared-utilities';
import { useConnection, useHasRole } from 'hive-react-utils';

export interface DataContextProps {
  provider?: Provider;
  providerResourceLocations?: ResourceLocation[];
  hasProviderRole?: boolean;
}

export const DataContext = createContext<DataContextProps>({});

export function DataContextProvider({
  children,
}: {
  children: null | JSX.Element;
}) {
  // IMPORTANT: just keep data that does NOT change often and that can be used often.
  const hasProviderRole = useHasRole('provider');
  const connection = useConnection();

  const provider = useMyProvider(connection?.username);
  const providerResourceLocations = useResourceLocationsByProviderId(
    provider?.properties.providerID
  );

  const value = useMemo<DataContextProps>(
    () => ({
      provider,
      providerResourceLocations,
      hasProviderRole,
    }),
    [provider, providerResourceLocations, hasProviderRole]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

export function useDataContext() {
  return useContext(DataContext);
}
